<template>
<div class="notice">
        <div class="cont">
          <van-row class="top" @click="getInfo('0')">
            <h3>人大在身边</h3>
            <h4>人大公告</h4>
            <span></span>
          </van-row>
          <van-row class="top mail" @click="getInfo('1')">
            <h3>人大信箱</h3>
            <h4>我的工作室</h4>
            <span></span>
          </van-row>
        </div>
  </div>
</template>

<script>
export default {
     data() {
        return {
        searchValue: '',
        loading: false,
        finished: false,
        limit: 10,
        page: 0,
        dataList: [],
        };
     },
    methods: {
        getInfo (type) {
          if (type === '0') {
            this.$router.push({path: '/sideNotice'})
          } else if (type === '1') {
            this.$router.push({path: '/peopleMail'})
          }
        }
    },
    created () {
    }
}
</script>

<style lang="scss" scoped>
.notice {
    //padding-top: 100px;
    .cont {
        //padding: 20px;
        background-color: #f5f5f5;
        .top {
            position: relative;
            height: 317px;
            border-radius: 10px;
            background-image: url("../../assets/img/we_bg1.png");
            background-size: 100%;
            background-repeat: no-repeat;
          h3 {
            font-size: 40px;
            font-weight: 400;
            font-family: PingFang-SC-Bold;
            color: #fff;
            padding-top: 57px;
            margin-bottom: 20px;
            padding-left: 30px;
          }
          h4 {
            color: #fff;
            opacity: 0.2;
            margin-top: 0;
            margin-left: 30px;
            font-size: 28px;
          }
          span {
            position: absolute;
            width: 48px;
            height: 7px;
            background: #FFFFFF;
            opacity: 0.5;
            border-radius: 4px;
            margin: 55px 0 0 48px;
          }
          img {
            position: absolute;
            top: 57px;
            left: 60px;
            width: 80px;
            height: 95px;
          }
        }
      .mail {
        background-image: url("../../assets/img/we_bg2.png");
      }
    }
}
</style>
